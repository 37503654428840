.iframe-content {
    width: 100%;
    height: 100vh;
}

.show-on-print, .show-on-print-table {
    display: none !important;
}

@media print {
    @page landscape_page {
        size: a4 landscape; 
    }

    iframe {
        height: auto !important;
        width: 100% !important;
    }

    .exclude_print {
        visibility: hidden;
    }
    .print_area {
        visibility: visible;
    }
    
    body {
        margin: 0;
        font-size: 12pt;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }

    .iframe-content {
        width: 100%;
        height: 50vh;
        overflow: visible;
    }
    .remove-background-print {
        background: none !important;
    }
    .remove-padding-print {
        padding: 0 !important;
    }

    .hide-on-print {
        display: none !important;
    }
    .show-on-print {
        display: block !important;
    }
    .show-on-print-table {
        display: table-cell !important;
    }
    
    table.survey_tablerow { page-break-after:auto !important }
    table.survey_tablerow tr th    { page-break-inside:avoid !important; page-break-after:auto !important }
    table.survey_tablerow tr td    { page-break-inside:avoid !important; page-break-after:auto !important }
    table.survey_tablerow thead { display:table-header-group !important }
    table.survey_tablerow tfoot { display:table-footer-group !important }
}