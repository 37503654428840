:root {
  --pri-blue: #003D7C;
  --pri-yellow: #ffc85b;
  --pri-grey: #999999;
  --btn-green: #42b868;
  --btn-blue: #1976D2;
  --btn-grey: #7F7F7F;
  --btn-lightgrey: #cbcbcb;
  --bg-light: #f7f7f9;
  --bg-yellow: #FFF6D7;
  --nus-footer1: #002449;
  --nus-footer2: #003062;
  --nus-orange: #ef7c00;
  --alert-error: #ffa3a3;
  --alert-warn: #ffe380;
  --alert-success: #b5e9a8;
}

::selection {
  color: #143d0a;
  background: #daffd1;
}

body {
  margin: 0;
  font-size: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.css-ahj2mt-MuiTypography-root {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif !important;
}

h1 {
  font-size: 6vw !important;
}
h1, h2, h3, h4, h5 {
  font-family: 'Outfit', sans-serif !important;
  font-weight: 700 !important;
}
h6 {
  font-family: 'Outfit', sans-serif !important;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

small {
    font-size: 0.7em;
}

.title-tab {
  font-family: 'Outfit', sans-serif !important;
  font-size: 1.3em !important;
}
.title-section {
  font-family: 'Outfit', sans-serif !important;
  font-size: 1.1em !important;
}
.title-subsection {
  font-family: 'Outfit', sans-serif !important;
  font-weight: 700 !important;
}
.secondary-title {
  font-family: 'Outfit', sans-serif !important;
  color: #6c757d;
}

.btn_anchor {
  background: none!important;
  border: none;
  padding: 0!important;
  color: #069;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: var(--nus-orange);
  }
}

a:-webkit-any-link {
  color: var(--btn-blue);
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: var(--nus-orange);
  }
}

.form-subsection {
  margin-bottom: 40px;
}

.form-field {
  padding: 1em 0;
  padding-left: 2em;
}


.MuiInputBase-root {
  width: 100% !important;
  padding:  0 14px !important;
  box-sizing: border-box;
  border-radius: 24px !important;
  background-color: #ffffffdf;
}

input[type=text], 
input[type=numeric] {
  padding:  8px !important;
}
.required:after {
	content:" *";
    color: red;
}

.pill_button {
  min-width: 20% !important;
  box-sizing: border-box !important;
  padding-left: 2em !important;
  padding-right: 2em !important;
  font-weight: 600 !important;
  border-radius: 24px !important;
  border: 2px solid var(--pri-blue) !important;
  background-color: var(--pri-blue) !important;
  color: white !important;
  &:hover {
    background-color: transparent !important;
    color: var(--pri-blue) !important;
  }
}
.outline_button {
  border: 2px solid var(--pri-blue) !important;
  background-color: transparent!important;
  color: var(--pri-blue) !important;
  &:hover {
    border: 2px solid var(--btn-blue) !important;
    color: var(--btn-blue) !important;
  }
}

.submit_button {
  background-color: var(--btn-green) !important;
  border: 2px solid var(--btn-green) !important;
  color: white !important;
}
.submit_button2 {
  background-color: transparent !important;
  border: 2px solid var(--btn-blue) !important;
  color: var(--btn-blue) !important;
}
.submit_button2:hover, .submit_button:hover {
  background-color: white !important;
  border: 2px solid var(--btn-green) !important;
  color: var(--btn-green) !important;
}

.secondary_button {
  background-color: var(--btn-grey) !important;
  border: 2px solid var(--btn-grey) !important;
  color: white !important;
}
.secondary_button:hover {
  background-color: white !important;
  color: var(--btn-grey) !important;
}

.disabled_button {
  background-color: var(--btn-lightgrey) !important;
  border: 2px solid var(--btn-lightgrey) !important;
  color: white !important;
}
.disabled_button:hover {
  background-color: white !important;
  color: var(--btn-lightgrey) !important;
}

.iconLink {
  color: var(--pri-blue) !important;
  &:hover {
    color: var(--btn-blue) !important;
  }
}

.calc-buttongroup {
  display: flex;
  justify-content: space-between;
}

select {
    width: 100% !important;
    padding: 12px 20px;
    margin: 8px 0;
}

.infoIcon:hover {
  color: var(--pri-yellow);
}

.centered-container {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}

.section-status {
    font-size: 0.8em;
    padding: 4px 16px;
    margin-left: 20px;
    display: flex;
    box-sizing: border-box;
    border-radius: 16px;
}

.chart_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer {
  color: var(--pri-grey);
}
.footer-weblink {
  cursor: pointer !important;
  color: var(--pri-grey) !important;
  &:hover {
    color: white !important;
    text-decoration: underline !important;
  }
}

.footer-top {
  background-color: var(--nus-footer1);
  color: white;
  padding: 16px;
}

.footer-bottom {
  background-color: var(--nus-footer2);
  padding: 24px;
  padding-bottom: 64px;
}

.footer-extension {
  background-color: var(--nus-footer2);
  border-top: 1.5px solid var(--pri-blue);
  display: flex;
  justify-content: center;
  padding: 40px 0px;
  font-size: 12px;
  font-family: "Frutiger", Helvetica, Arial, Helvetica, sans-serif;
}
.nus-footer-bar {
  display: flex;
  justify-content: space-between;
}
.ast-small-footer-section-1 {
  margin-bottom: 7px;
}

.light-background {
  background-color: var(--bg-light);
}


/* 
Custom Loader (Flashing Icons)
*/
.lds-calc-icons {
  color: var(--btn-grey);
}
.lds-calc-icons div {
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-calc-icons div:nth-child(1) {
  animation: lds-calc-icons1 2s infinite;
}
.lds-calc-icons div:nth-child(2) {
  animation: lds-calc-icons2 2s infinite;
}
.lds-calc-icons div:nth-child(3) {
  animation: lds-calc-icons3 2s infinite;
}
@keyframes lds-calc-icons1 {
  0% {
    transform: scale(0.6);
  }
  33% {
    transform: scale(1.2);
  }
  66% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(0.6);
  }
}
@keyframes lds-calc-icons2 {
  0% {
    transform: scale(0.6);
  }
  33% {
    transform: scale(0.6);
  }
  66% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0.6);
  }
}
@keyframes lds-calc-icons3 {
  0% {
    transform: scale(0.6);
  }
  33% {
    transform: scale(0.6);
  }
  66% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1.2);
  }
}
/* end of Custom Loader  */

/* 
Custom Loader 1 (Calculator Buttons)
Source: https://loading.io/css/ 
*/
  .lds-grid {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: auto;
  }
  .lds-grid div {
    position: absolute;
    /* width: 16px;
    height: 16px; */
    width: 25%;
    aspect-ratio: 1;
    border-radius: 4px;
    background: var(--pri-blue);
    animation: lds-grid 1.2s linear infinite;
  }
  .lds-grid .icon {
    color: var(--pri-blue);
    background: transparent !important;
    display: flex;
    justify-content: center;
  }
  .lds-grid div:nth-child(1) {
    top: 4%;
    left: 4%;
    animation-delay: 0s;
  }
  .lds-grid div:nth-child(2) {
    top: 4%;
    left: 37%;
    animation-delay: -0.4s;
  }
  .lds-grid div:nth-child(3) {
    top: 4%;
    left: 70%;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(4) {
    top: 37%;
    left: 4%;
    animation-delay: -0.4s;
  }
  .lds-grid div:nth-child(5) {
    top: 37%;
    left: 37%;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(6) {
    top: 37%;
    left: 70%;
    animation-delay: -1.2s;
  }
  .lds-grid div:nth-child(7) {
    top: 70%;
    left: 4%;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(8) {
    top: 70%;
    left: 37%;
    animation-delay: -1.2s;
  }
  .lds-grid div:nth-child(9) {
    top: 70%;
    left: 70%;
    animation-delay: -1.6s;
  }
  @keyframes lds-grid {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.25;
    }
  }
/* end of Custom Loader  */
  
/* 
Custom Loader 2 (Ellipsis)
Source: https://loading.io/css/ 
*/
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 1em;
}
.lds-ellipsis div {
  position: absolute;
  top: 0.25em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/* 
Custom Loader 3 (Spinner)
Source: https://css-loaders.com/spinner/ 
*/
.spinner {
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}
/* end of Custom Loader 3 */

.parallax {
  /* The image used */
  background-image: url("./carbonCalc/images/404image.png");
  /* Set a specific height */
  min-height: 500px;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

  @media screen and (max-width: 600px) {
    h1 {
      font-size: 18vw !important;
    }

    .calc-buttongroup {
      flex-direction: column;
    }

    .pill_button {
        width: 100%;
        margin: 0 !important;
        margin-top: 1em !important;
    }

    .form-field {
      padding-left: 10px;
    }
  }

.ccp_white_box {
  box-sizing: border-box;
  border-radius: 28px;
  backdrop-filter: blur(3px);
  background-color: #ffffffa8;
  width: 100%;
  overflow: hidden;
};